import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import store from '@/store';

import { UserAuthenticator } from '../authenticators/userAuthenticator';
import MsalFactory from '../factories/msalFactory';
import * as msal from '@azure/msal-browser';

// Views
import Signin from '../views/auth/signin/signin.vue';
import Signup from '../views/auth/signup/signup.vue';
import SignupOTP from '../views/auth/signup/signup-otp.vue';
import SignupForm from '../views/auth/signup/signup-form.vue';
import SignupEmailError from '../views/auth/signup/signup-email-error.vue';
import SignupRegistrationError from '../views/auth/signup/signup-registration-error.vue';
import SignupComplete from '../views/auth/signup/signup-complete.vue';
import SignupOTPResendSuccess from '../views/auth/signup/signup-otpresend-success.vue';
import SignupSuccess from '../views/auth/signup/signup-success.vue';
import SignupLastStep from '../views/auth/signup/signup-laststep.vue';
import LastStepSuccess from '../views/auth/signup/laststep-success.vue';

import ForgotPassword from '../views/auth/forgotPassword/forgotPassword.vue';
import SentEmail from '../views/auth/forgotPassword/sentEmail.vue';
import ResetSentEmail from '../views/auth/forgotPassword/resetSentEmail.vue';
import VerificationCode from '../views/auth/forgotPassword/verificationCode.vue';
import VerificationResend from '../views/auth/forgotPassword/verificationResend.vue';
import ResetPassword from '../views/auth/forgotPassword/resetPassword.vue';

import Dashboard from '../views/dashboard.vue';
import Home from '../views/home/home.vue';
import Profile from '../views/profile/profile.vue';
import OrderLines from '../views/orderLines/orderLines.vue';
import Compliance from '../views/compliance/compliance.vue';
import OrderEvidence from '../views/orderEvidence/orderEvidence.vue';
import Footprint from '../views/footprint/footprint.vue';
import Esg from '../views/esg/esg.vue'

MsalFactory.initialize();
Vue.use(VueRouter);

const routes: RouteConfig[] = [
  {
    path: '/auth/signin',
    name: 'Signin',
    component: Signin,
  },
  {
    path: '/auth/signup',
    name: 'Signup',
    component: Signup,
  },
  {
    path: '/auth/forgotPassword',
    name: 'ForgotPassword',
    component: ForgotPassword,
  },
  {
    path: '/auth/resetSentEmail',
    name: 'ResetSentEmail',
    component: ResetSentEmail,
  },
  {
    path: '/auth/sentEmail',
    name: 'SentEmail',
    component: SentEmail,
  },
  {
    path: '/auth/verificationCode',
    name: 'VerificationCode',
    component: VerificationCode,
  },
  {
    path: '/auth/verificationResend',
    name: 'VerificationResend',
    component: VerificationResend,
  },
  {
    path: '/auth/resetPassword',
    name: 'ResetPassword',
    component: ResetPassword,
  },
  {
    path: '/auth/signup/email-error',
    name: 'SignupEmailError',
    component: SignupEmailError,
  },
  {
    path: '/auth/signup/registration-error',
    name: 'SignupRegistrationError',
    component: SignupRegistrationError,
  },
  {
    path: '/auth/signup/otp-resend-success',
    name: 'SignupOTPResendSuccess',
    component: SignupOTPResendSuccess,
  },
  {
    path: '/auth/signup/otp',
    name: 'SignupOTP',
    component: SignupOTP,
  },
  {
    path: '/auth/signup/form',
    name: 'SignupForm',
    component: SignupForm,
  },
  {
    path: '/auth/signup/success',
    name: 'SignupSuccess',
    component: SignupSuccess,
  },
  {
    path: '/auth/signup/laststep',
    name: 'SignupLastStep',
    component: SignupLastStep,
  },
  {
    path: '/auth/signup/laststep-success',
    name: 'LastStepSuccess',
    component: LastStepSuccess,
  },
  {
    path: '/',
    name: 'Home',
    component: Dashboard,
    children: [
      {
        path: '/',
        name: 'Home',
        component: Home,
      },
      {
        path: '/account',
        name: 'Profile',
        component: Profile,
      },
      {
        path: '/compliance',
        name: 'Compliance',
        component: Compliance,
      },
      {
        path: '/order-lines',
        name: 'OrderLines',
        component: OrderLines,
      },
      {
        path: '/supply-chain-partners',
        name: 'SupplyChainPartners',
      },
      {
        path: '/order-evidence',
        name: 'OrderEvidence',
        component: OrderEvidence,
      },
      //TTD-2736 sprint-19 development
      {
        path: '/footprint',
        name: 'Footprint',
        component: Footprint
      },
      {
        path: '/esg',
        name: 'Esg',
        component: Esg
      }
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

const clearAppCache = () => {
  if (window.caches) {
    caches.keys().then((cacheNames) => {
      cacheNames.forEach((cacheName) => {
        caches.delete(cacheName);
      });
    });
  }
};

router.beforeEach((to, from, next) => {
if (from.fullPath == '/' || from.fullPath == '/auth/signup/laststep' ||
 from.fullPath == '/auth/signin' || from.fullPath == '/account' ||
 from.fullPath == '/auth/signup/form' || from.fullPath == '/auth/forgotPassword' ||
 from.fullPath == '/auth/signup' || from.fullPath == '/auth/verificationCode') {
    clearAppCache();
  }
  const authRoutes = '/auth';
  const apiInvitationProcessor = '/api/InvitationProcessor/';
  const accountRedir = "/accountSettings";

  const accessToken = sessionStorage.getItem('access_token');
  switch (true) {
    case to.path?.startsWith(accountRedir):
      {
        if (to.query && to.query.pendingUser) {
          next({ name: 'Signin', params: { 'settings': '1'} });
        }
        break;
      }
    case to.path?.startsWith(authRoutes):
      {
      next();
      break;
      }
    case to.path?.startsWith(apiInvitationProcessor):
      {
      if (to.path.split(apiInvitationProcessor).length > 1) {
        const invitationId = to.path.split(apiInvitationProcessor)[1];
        localStorage.setItem('invitationId', invitationId);
      }

      // eslint-disable-next-line no-case-declarations
      const email = decodeURIComponent(to.query.email.toString());
      if (email !== '' && email !== null) {
        localStorage.setItem('invitationEmail', email);
      }
      next({ name: 'SignupLastStep', params: { emailAddress: email } });
      break;
    }

    default:
    {
      if (accessToken !== null && accessToken !== undefined) {
        const invitationId = localStorage.getItem('invitationId');
        if (!invitationId) {
          if (store.getters.canNavigate) {
            if(store.getters.isFootprintChanged === ""){
              next();
            }
            else{
              next({ name: 'Footprint' });
              store.commit('setFootprintConfirmation', store.getters.isFootprintChanged);
            }
            if(!store.getters.isEsgDataChanged){
              next();
            }
            else{
              next({ name: 'Esg' });
              store.commit('setShowEsgConfirmation', true);
            }
          } else {
            next({ name: 'Profile' });
          }
        } else {
          if (to.name !== 'Profile') {
            next({ name: 'Profile' });
          } else {
            next();
          }
        }
      } else {
        next({ name: 'Signin' });
        return;
      }
      break;
    }
  }
});

export default router;
