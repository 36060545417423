import { ActionContext } from 'vuex';
import i18n from '@/i18n';
import { cloneDeep } from 'lodash';
import { IState } from '../state';
import _ from 'underscore';

// Helpers
import { NotificationHelper } from '@/helpers/notificationHelper';

// Services
import { OrderService } from '@/services/orderService';
import { SupplierService } from '@/services/supplierService';
import { ComplianceCertificatesService } from '@/services/complianceCertificatesService';
import { UserService } from '@/services/userService';
import { MiddlewareService } from '@/services/middlewareService';
import { ComplianceService } from '@/services/complianceService';

// Models
import { CertificateModel, CertificateOnTypeModel, CertificateTypes } from '@/models/certificateModel';
import {
  UserRolePermissionsModel,
  UserPermissionRequestModel,
} from '@/models/permissionModel';
import { BlockchainAccessModal } from '@/models/blockchainAccessModal';
import { OrderLineStepDisplayModel } from '@/models/orderLineStepDisplayModel';
import { EvidenceStepCountModel } from '@/models/orderLineEvidenceStep';
import { StepState } from '@/models/stepState';
import { Guid } from '@/models/guid';
import { FootPrintCompletedConstant } from "@/models/footprintModel";

export enum ActionTypes {
  SET_ORDER = 'setOrders',
  SET_PENDING_ORDERS = 'setPendingOrders',
  SET_ORDERLINE = 'setOrderLines',
  SET_MYACCOUNT = 'setMyAccount',
  SET_ORDER_LOADING = 'setOrderLoading',
  SET_ORDER_LINE_STEPS = 'setOrderLineSteps',
  SET_PENDING_ORDER_LINE_STEPS = 'setPendingOrderLineSteps',
  SET_STYLE_COUNT = 'setStyleCount',
  SET_ALL_STYLE_COUNT = 'setAllStyleCount',
  SET_EXPIRING_CERT_COUNT = 'setExpiringCertCount',
  SET_PENDING_ORDER_LINE_LOADING = 'setPendingOrderLineLoading',
  SET_COMPLIANCE_CERTIFICATE_LOADING = 'setComplainceCertificateLoading',
  SET_COMPLIANCE_CERTIFICATES = 'setComplianceCertificates',
  SET_ORDER_LINE_EVIDENCE_STEPS_LOADING = 'setOrderLineEvidenceStepsLoading',
  SET_ORDER_EVIDENCE = 'setOrderEvidence',
  SET_ORDER_LINE_EVIDENCE_STEPS = 'setOrderLineEvidenceSteps',
  SET_ORDER_LINE_EVIDENCE_NEIGHBOUR_STEPS = 'setOrderLineEvidenceNeighbourSteps',
  SET_ORDER_LINE_EVIDENCE_APPROVE_STEPS = 'setOrderLineEvidenceApproveSteps',
  SET_ORDER_LINE_EVIDENCE_COUNT_LOADING = 'setOrderLineEvidenceCountLoading',
  SET_ORDER_LINE_EVIDENCE_COUNT = 'setOrderLineEvidenceCount',
  SET_USER_ROLE_PERMISSIONS = 'setUserRolePermissions',
  SET_SOCIAL_CERTIFICATES = 'setSocialCertificates',
  SET_SUSTAINABLE_CERTIFICATES = 'setSustainableCertificates',
  SET_FOOTPRINT_COUNT = 'setFootprintCount',
  SET_FOOTPRINT_ORDERS = 'setFootprintOrders',
  SET_DELEGATED_COUNT = 'setDelegatedCount',
  // Sprint-24 TTD-3798
  SET_READ_OR_SIGN_DOCS = 'setReadOrSignDocs',
  SET_EXP_COUNT = 'setExpiredCertificateCount',
  SET_SIGN_READ_DOC_COUNT = 'setReadOrSignDocumentsCount',
  SET_COMPANY_NAMES_LIST = 'setCompanyNameList',
  SET_ESG_SOCIAL_DATA = 'setEsgSocialData',
  SET_ESG_ENV_DATA = 'setEsgEnvData',
}

export class Actions {
  public static async setOrderLines({
    commit,
    dispatch,
    state,
  }: ActionContext<IState, IState>): Promise<void> {
    if (state.isAppOnline && (state.user?.isT1Scp || state.user?.isDelegatePartner)) {
      const service = new OrderService();
      commit(ActionTypes.SET_ORDER_LOADING, true);
      commit(ActionTypes.SET_ORDER, []);
      commit(ActionTypes.SET_PENDING_ORDERS, []);
      commit(ActionTypes.SET_DELEGATED_COUNT, 0);
      commit(ActionTypes.SET_ORDER_LINE_STEPS, []);
      commit(ActionTypes.SET_PENDING_ORDER_LINE_STEPS, []);
      commit(ActionTypes.SET_ALL_STYLE_COUNT, 0);
      commit(ActionTypes.SET_EXPIRING_CERT_COUNT, 0);
      // commit(ActionTypes.SET_FOOTPRINT_COUNT, 0);
      // commit(ActionTypes.SET_FOOTPRINT_ORDERS, []);
      let bookmark = '';
      let count = 1;
      if(state.companyNameList.length === 0){
        await dispatch(ActionTypes.SET_COMPANY_NAMES_LIST);
      }
      try {
        await service
          .getOrdersAsync(
            bookmark,
            process.env.VUE_APP_pageSize !== undefined
              ? Number(process.env.VUE_APP_pageSize)
              : 500
          )
          .then((response) => {
            if (response !== null) {
              const orders = response.orders.filter(
                (o) =>
                  (o.orderLineStatus !== 0)
              );
              //TTD-2736 sprint-19 show foot print orders count
              //TTD-3931: Final approach decided to get footprint orders and count from metrices api - 19/02/2024
              // const footprintOrders = response.orders.filter(
              //   (o) =>
              //     (o.footprintFlag === "TRUE")
              // );
              const pendingOrders = response.orders.filter(
                (o) =>
                  (o.orderLinePhase === 1 && o.orderLineStatus === 0)
              );
              let totalDelegated = 0;
              totalDelegated = pendingOrders.filter(d=>d.delegateCompanyID!==Guid.Empty && d.delegateCompanyID === state.user?.companyId).length;
              const steps = response.orderLineSteps.filter(
                (s) => s.stepState !== StepState.DELETED_BY_CLIENT && s.stepState !== StepState.DELETED_BY_AGENT && s.stepState !== StepState.DELETED_BY_SUPPLIER && s.stepState !== StepState.DELETED_BY_DELEGATEPARTNER && s.stepState !== StepState.NONE
              );
              const orderLineSteps: OrderLineStepDisplayModel[] = [];
              const pendingOrderLineSteps: OrderLineStepDisplayModel[] = [];
              orders.forEach(order => {
                // steps
                const lineSteps = steps.filter(s => s.orderId === order.orderId);
                orderLineSteps.push(...lineSteps);
                // map update company name to orders
                order.fromCompanyName = state.companyNameList.filter(c => c.ID === order.fromCompanyUser.ID).length > 0 ? state.companyNameList.filter(c => c.ID === order.fromCompanyUser.ID)[0].name : order.fromCompanyName;
                order.fromCompanyUser.name = state.companyNameList.filter(c => c.ID === order.fromCompanyUser.ID).length > 0 ? state.companyNameList.filter(c => c.ID === order.fromCompanyUser.ID)[0].name : order.fromCompanyUser.name;
                order.toCompanyName = state.companyNameList.filter(c => c.ID === order.toCompanyId).length > 0 ? state.companyNameList.filter(c => c.ID === order.toCompanyId)[0].name : order.toCompanyName;
                order.agentCompanyUser.name = state.companyNameList.filter(c => c.ID === order.agentCompanyID).length > 0 ? state.companyNameList.filter(c => c.ID === order.agentCompanyID)[0].name : order.agentCompanyUser.name;
                order.delegateCompanyUser.companyName = state.companyNameList.filter(c => c.ID === order.delegateCompanyID).length > 0 ? state.companyNameList.filter(c => c.ID === order.delegateCompanyID)[0].name : order.delegateCompanyUser.companyName;
              });
              pendingOrders.forEach(pOrder => {
                const pendingLineSteps = steps.filter(ps => ps.orderId === pOrder.orderId);
                pendingOrderLineSteps.push(...pendingLineSteps);
                // map update company name to orders
                pOrder.fromCompanyName = state.companyNameList.filter(c => c.ID === pOrder.fromCompanyUser.ID).length > 0 ? state.companyNameList.filter(c => c.ID === pOrder.fromCompanyUser.ID)[0].name : pOrder.fromCompanyName;
                pOrder.fromCompanyUser.name = state.companyNameList.filter(c => c.ID === pOrder.fromCompanyUser.ID).length > 0 ? state.companyNameList.filter(c => c.ID === pOrder.fromCompanyUser.ID)[0].name : pOrder.fromCompanyUser.name;
                pOrder.toCompanyName = state.companyNameList.filter(c => c.ID === pOrder.toCompanyId).length > 0 ? state.companyNameList.filter(c => c.ID === pOrder.toCompanyId)[0].name : pOrder.toCompanyName;
                pOrder.agentCompanyUser.name = state.companyNameList.filter(c => c.ID === pOrder.agentCompanyID).length > 0 ? state.companyNameList.filter(c => c.ID === pOrder.agentCompanyID)[0].name : pOrder.agentCompanyUser.name;
                pOrder.delegateCompanyUser.companyName = state.companyNameList.filter(c => c.ID === pOrder.delegateCompanyID).length > 0 ? state.companyNameList.filter(c => c.ID === pOrder.delegateCompanyID)[0].name : pOrder.delegateCompanyUser.companyName;
              });
              commit(ActionTypes.SET_ORDER, orders);
              commit(ActionTypes.SET_PENDING_ORDERS, pendingOrders);
              commit(ActionTypes.SET_DELEGATED_COUNT, totalDelegated);
              commit(ActionTypes.SET_ORDER_LINE_STEPS, orderLineSteps);
              commit(ActionTypes.SET_PENDING_ORDER_LINE_STEPS, pendingOrderLineSteps);
              commit(ActionTypes.SET_STYLE_COUNT, response.totalStyles);
              //TTD-2736 sprint-19 show foot print orders count
              // commit(ActionTypes.SET_FOOTPRINT_ORDERS, footprintOrders);
              // commit(ActionTypes.SET_FOOTPRINT_COUNT, footprintOrders.length);
              // TTD-3931: Final approach decided to get footprint orders and count from metrices api - 19/02/2024
              // if(state.user){
              //   const user = state.user;
              //   if(user.isT1Scp === true){
              //     commit(ActionTypes.SET_FOOTPRINT_ORDERS, []);
              //     commit(ActionTypes.SET_FOOTPRINT_COUNT, 0);
              //     commit(ActionTypes.SET_FOOTPRINT_COUNT, response.provideFootprint);
              //     commit(ActionTypes.SET_FOOTPRINT_ORDERS, footprintOrders);
              //   }
              // }
              bookmark = response.bookmark;
              count = response.count;
            }
          })
          .catch((reason) => {
            console.error('Error while retrieving an order by id', reason);
          });
        // Radnik supplier facing pagination issue as data is coming from Redis so as confirmed by chinmay removed pagination code
        // if (count === Number(process.env.VUE_APP_pageSize)) {
        //   while (count === Number(process.env.VUE_APP_pageSize)) {
        //     await service
        //       .getOrdersAsync(
        //         bookmark,
        //         process.env.VUE_APP_pageSize !== undefined
        //           ? Number(process.env.VUE_APP_pageSize)
        //           : 500
        //       )
        //       .then((response) => {
        //         if (response !== null) {
        //           const filteredOrders = response.orders.filter(
        //             (o) =>
        //               (o.orderLineStatus !== 0)
        //             );
        //           const filteredPendingOrders = response.orders.filter(
        //             (o) =>
        //               (o.orderLinePhase === 1 && o.orderLineStatus === 0)
        //             );
        //           const pagSteps = response.orderLineSteps.filter(
        //             (s) => s.stepState !== 3 && s.stepState !== 6
        //           );
        //           const pagOrderLineSteps: OrderLineStepDisplayModel[] = [];
        //           const pagPendingOrderLineSteps: OrderLineStepDisplayModel[] = [];
        //           filteredOrders.forEach(order => {
        //             const lineSteps = pagSteps.filter(s => s.orderId === order.orderId);
        //             pagOrderLineSteps.push(...lineSteps);
        //           });
        //           filteredPendingOrders.forEach(pOrder => {
        //             const pendingLineSteps = pagSteps.filter(ps => ps.orderId === pOrder.orderId);
        //             pagPendingOrderLineSteps.push(...pendingLineSteps);
        //           });
        //           // pagination orders cloned and pushed in main orders array
        //           const orders = cloneDeep(state.orders);
        //           orders.push(...filteredOrders);
        //           commit(ActionTypes.SET_ORDER, orders);
        //           // pagination pending orders cloned and pushed in main penindg orders array
        //           const pendingOrders = cloneDeep(state.pendingOrders);
        //           pendingOrders.push(...filteredPendingOrders);
        //           commit(ActionTypes.SET_PENDING_ORDERS, pendingOrders);
        //           // pagination steps cloned and pushed in main steps array
        //           const orderLineSteps = cloneDeep(state.orderLineSteps);
        //           orderLineSteps.push(...pagOrderLineSteps);
        //           commit(ActionTypes.SET_ORDER_LINE_STEPS, orderLineSteps);
        //           // pagination pending steps cloned and pushed in main pending steps array
        //           const pendingOrderLineSteps = cloneDeep(state.pendngOrderLineSteps);
        //           pendingOrderLineSteps.push(...pagPendingOrderLineSteps);
        //           commit(ActionTypes.SET_PENDING_ORDER_LINE_STEPS, pendingOrderLineSteps);
        //           commit(ActionTypes.SET_STYLE_COUNT, response.totalStyles);
        //           bookmark = response.bookmark;
        //           count = response.count;
        //         }
        //       })
        //       .catch((reason) => {
        //         console.error('Error while retrieving an order by id', reason);
        //       });
        //   }
        // }
      } catch (reason) {
        console.error('Error while retrieving an order by id', reason);
        NotificationHelper.createErrorNotification(
          i18n
            .t('global.notifications.could_not_retrieve_orderlinesteps')
            .toString()
        );
      } finally {
        commit(ActionTypes.SET_ORDER_LOADING, false);
      }
    }
    return Promise.resolve();
  }

  public static async setMyAccount({
    commit,
    dispatch,
    state,
  }: ActionContext<IState, IState>): Promise<void> {
    if (state.isAppOnline) {
      const service = new SupplierService();
      commit(ActionTypes.SET_MYACCOUNT, null);
      try {
        await service
          .getSupplierAsync()
          .then((response) => {
            if (response !== null) {
              commit(ActionTypes.SET_MYACCOUNT, response);
            }
          })
          .catch((reason) => {
            //
          });
      } catch (reason) {
        console.error('Error while retrieving an order by id', reason);
        NotificationHelper.createErrorNotification(
          i18n
            .t('global.notifications.could_not_retrieve_orderlinesteps')
            .toString()
        );
      } finally {
        //
      }
    }
    return Promise.resolve();
  }

  public static setPendingOrderLineLoading(
    { commit, state, dispatch }: ActionContext<IState, IState>,
    pendingOrderLoading: boolean
  ): Promise<void> {
    commit(ActionTypes.SET_PENDING_ORDER_LINE_LOADING, pendingOrderLoading);
    return Promise.resolve();
  }

  public static async setComplianceCertificates({
    commit,
    state,
    dispatch,
  }: ActionContext<IState, IState>): Promise<void> {
    if (state.isAppOnline) {
      const service = new ComplianceCertificatesService();
      commit(ActionTypes.SET_COMPLIANCE_CERTIFICATE_LOADING, true);
      commit(ActionTypes.SET_COMPLIANCE_CERTIFICATES, []);
      try {
        const user = state.user;
        let certificates: CertificateModel[] = [];
        const socMembership = i18n.t(`enums.social_certificates.membership`) as any;
        const socMemCerts:CertificateOnTypeModel[] = [];
        for (const key of Object.keys(socMembership)) {
              const certifcateName = socMembership[key];
              socMemCerts.push(new CertificateOnTypeModel(key, certifcateName, CertificateTypes.MEMBERSHIP, CertificateTypes.SUSTAINABILITY));
            }
        await service
          .getSocialCertificatesAsync(user !== null ? user.companyId : '')
          .then((response) => {
            const socCertificates = response.filter(c => socMemCerts.findIndex(soc => soc.ID === c.type.toString())===-1 && c.type!==0 && c.status!==CertificateTypes.ARCHIVED);
            certificates.push(...socCertificates);
            // Sprint-24 TTD-3798
            const socialCert = response;
            const currentDate = new Date();
            let sociaEexpireCount = socialCert.filter(sc => socMemCerts.findIndex(soc => soc.ID === sc.type.toString())===-1 && sc.type!==0 && new Date(sc.validtoDate) < currentDate ).length;
            commit(ActionTypes.SET_EXP_COUNT, sociaEexpireCount);
            commit(ActionTypes.SET_SOCIAL_CERTIFICATES, response);
          });
          const susMembership = i18n.t(`enums.sustainability_certificates.membership`) as any;
          const sustainableMemCerts:CertificateOnTypeModel[] = [];
          for (const key of Object.keys(susMembership)) {
              const certifcateName = susMembership[key];
              sustainableMemCerts.push(new CertificateOnTypeModel(key, certifcateName, CertificateTypes.MEMBERSHIP, CertificateTypes.SUSTAINABILITY));
            }
        await service
          .getSustainableCertificatesAsync(user !== null ? user.companyId : '')
          .then((response) => {
            const susCertificates = response.filter(c => sustainableMemCerts.findIndex(sus => sus.ID === c.type.toString())===-1 && c.type!==0 && c.status!==CertificateTypes.ARCHIVED);
            certificates.push(...susCertificates);
             // Sprint-24 TTD-3798
            const susCert = response;
            const currentDate = new Date();
            let susExpireCount = susCert.filter(sc => sustainableMemCerts.findIndex(sus => sus.ID === sc.type.toString())===-1 && sc.type!==0 && new Date(sc.validtoDate) < currentDate).length;
            commit(ActionTypes.SET_EXP_COUNT, susExpireCount);
            commit(ActionTypes.SET_SUSTAINABLE_CERTIFICATES, response);
          });
        commit(ActionTypes.SET_COMPLIANCE_CERTIFICATES, certificates);
      } catch (reason) {
        console.error('Error while retrieving an order by id', reason);
        NotificationHelper.createErrorNotification(
          i18n
            .t(
              'global.notifications.could_not_retrieve_complaince_certificates'
            )
            .toString()
        );
      } finally {
        commit(ActionTypes.SET_COMPLIANCE_CERTIFICATE_LOADING, false);
      }
    }
  }

  public static setOrderLineEvidenceSteps({
    commit,
    dispatch,
    state,
  }: ActionContext<IState, IState>): Promise<void> {
    if (state.isAppOnline) {
      const service = new OrderService();

      commit(ActionTypes.SET_ORDER_LINE_EVIDENCE_STEPS_LOADING, true);
      commit(ActionTypes.SET_ORDER_EVIDENCE, []);
      commit(ActionTypes.SET_ORDER_LINE_EVIDENCE_STEPS, []);
      commit(ActionTypes.SET_ORDER_LINE_EVIDENCE_NEIGHBOUR_STEPS, []);
      commit(ActionTypes.SET_ORDER_LINE_EVIDENCE_APPROVE_STEPS, []);
      const companyId = state.user && state.user.companyId !== null ? state.user.companyId : '';
      return service
        .getOrderLineEvidenceStepsByStatusAsync(companyId)
        .then((response) => {
          if (response != null) {
            commit(ActionTypes.SET_ORDER_EVIDENCE, response.orders);
            const orderLineEvidenceSteps = response.orderLineSteps.filter(
              (s) => s.stepState !== StepState.DELETED_BY_CLIENT && s.stepState !== StepState.DELETED_BY_AGENT && s.stepState !== StepState.DELETED_BY_SUPPLIER && s.stepState !== StepState.DELETED_BY_DELEGATEPARTNER && s.stepState !== StepState.NONE
            );
            commit(
              ActionTypes.SET_ORDER_LINE_EVIDENCE_STEPS,
              orderLineEvidenceSteps
            );
            commit(
              ActionTypes.SET_ORDER_LINE_EVIDENCE_NEIGHBOUR_STEPS,
              response.steps
            );
            commit(
              ActionTypes.SET_ORDER_LINE_EVIDENCE_APPROVE_STEPS,
              response.approvedSteps
            );
          }
        })
        .catch((reason) => {
          console.error('Error while retrieving an order evidence', reason);
          NotificationHelper.createErrorNotification(
            i18n
              .t('global.notifications.could_not_retrieve_orderlinesteps')
              .toString()
          );
        })
        .finally(() => {
          commit(ActionTypes.SET_ORDER_LINE_EVIDENCE_STEPS_LOADING, false);
        });
    }
    return Promise.resolve();
  }

  public static setOrderLineEvidenceCount({commit, state, dispatch}: ActionContext<IState, IState>): Promise<void> {
    if (state.isAppOnline) {
      const service = new OrderService();
      commit(ActionTypes.SET_ORDER_LINE_EVIDENCE_COUNT_LOADING, true);
      const evidenceCount: EvidenceStepCountModel = {
        success: true,
        orderLineSteps: 0,
        approvedSteps: 0,
        orders: [],
        provideFootprint: 0
      }
      commit(ActionTypes.SET_ORDER_LINE_EVIDENCE_COUNT, evidenceCount);
      const companyId = state.user && state.user.companyId !== null ? state.user.companyId : '';
      return service
        .getEvidenceStepsCountAsync(companyId)
        .then((response) => {
          if (response.success) {
            commit(ActionTypes.SET_ORDER_LINE_EVIDENCE_COUNT, response);
            // This is not required as metric api will used for all companyType of supplier for footprint
            // if(state.user){
            //   const isT1Scp = state.user.isT1Scp
            //   if(!isT1Scp){
                commit(ActionTypes.SET_FOOTPRINT_COUNT, 0);
                commit(ActionTypes.SET_FOOTPRINT_ORDERS, []);
                const footprintOrders = response.orders.filter(
                  (o) =>
                    (o.footprintFlag === "TRUE")
                );
                footprintOrders.forEach(order => {
                  order.fromCompanyName = state.companyNameList.filter(c => c.ID === order.fromCompanyUser.ID).length > 0 ? state.companyNameList.filter(c => c.ID === order.fromCompanyUser.ID)[0].name : order.fromCompanyName;
                  order.fromCompanyUser.name = state.companyNameList.filter(c => c.ID === order.fromCompanyUser.ID).length > 0 ? state.companyNameList.filter(c => c.ID === order.fromCompanyUser.ID)[0].name : order.fromCompanyUser.name;
                  order.toCompanyName = state.companyNameList.filter(c => c.ID === order.toCompanyId).length > 0 ? state.companyNameList.filter(c => c.ID === order.toCompanyId)[0].name : order.toCompanyName;
                  order.agentCompanyUser.name = state.companyNameList.filter(c => c.ID === order.agentCompanyID).length > 0 ? state.companyNameList.filter(c => c.ID === order.agentCompanyID)[0].name : order.agentCompanyUser.name;
                  order.delegateCompanyUser.companyName = state.companyNameList.filter(c => c.ID === order.delegateCompanyID).length > 0 ? state.companyNameList.filter(c => c.ID === order.delegateCompanyID)[0].name : order.delegateCompanyUser.companyName;
                })
                //Sprint-22 TTD-3942
                const provideFootprintCount = response.orders.filter(o => o.footprintFlag === 'TRUE' && (o.finalFootprintStatus === FootPrintCompletedConstant.PARTIAL || o.finalFootprintStatus === "" || o.actionOnFootprint.status === FootPrintCompletedConstant.FORCEINPROGRESS)
                && o.actionOnFootprint.status !== FootPrintCompletedConstant.FORCECOMPLETED).length;
                commit(ActionTypes.SET_FOOTPRINT_COUNT, provideFootprintCount); // TTD-3931
                commit(ActionTypes.SET_FOOTPRINT_ORDERS, footprintOrders);
            //   }
            // }
          }
        })
        .catch((reason) => {
          console.error('Error while retrieving an order evidence count', reason);
          // NotificationHelper.createErrorNotification(
          //   i18n
          //     .t('global.notifications.could_not_retrieve_orderlinesteps')
          //     .toString()
          // );
        })
        .finally(() => {
          commit(ActionTypes.SET_ORDER_LINE_EVIDENCE_COUNT_LOADING, false);
        });
    }
    return Promise.resolve();
  }

  public static async setUserRolePermissions({
    commit,
    state,
    dispatch,
  }: ActionContext<IState, IState>): Promise<void> {
    const service = new UserService();
    const mwService = new MiddlewareService();
    try {
      let response: BlockchainAccessModal = new BlockchainAccessModal();
      if (state.accessToken !== null) {
        response = state.accessToken;
      } else {
        response = await service.getBlockchainToken();
        commit('setAccessToken', response);
      }
      if (response !== null) {
        const token = response.token;
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(
          window
            .atob(base64)
            .split('')
            .map(function (c) {
              return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join('')
        );
        const result = JSON.parse(jsonPayload);
        let userPermissions: UserRolePermissionsModel = result.permissions[0];
        if (
          state.user &&
          state.user!.companyType &&
          state.user.companyType !== 1 &&
          state.user.companyType !== 5
        ) {
          if (userPermissions.groupName === 'Admin') {
            var json = require('../../permissions/cpAdminPermission.json');
          } else if (userPermissions.groupName === 'Editor') {
            var json = require('../../permissions/cpEditorPermissions.json');
          } else {
            var json = require('../../permissions/cpReaderPermission.json');
          }
          if (!_.isEqual(userPermissions.permissions, json.permissions)) {
            const data: UserPermissionRequestModel =
              new UserPermissionRequestModel();
            data.companyID = userPermissions.companyID;
            data.groupID = userPermissions.groupID;
            data.companyType =
              state.user !== null ? state.user!.companyType : 1;
            const permissionResponse = await mwService.updateUserPermission(
              data
            );
            if (permissionResponse.result === 'success') {
              let tokenResponse: BlockchainAccessModal =
                new BlockchainAccessModal();
              tokenResponse = await service.getBlockchainToken();
              commit('setAccessToken', tokenResponse);
              const newToken = tokenResponse.token;
              const newBase64Url = newToken.split('.')[1];
              const newBase64 = newBase64Url
                .replace(/-/g, '+')
                .replace(/_/g, '/');
              const newJsonPayload = decodeURIComponent(
                window
                  .atob(newBase64)
                  .split('')
                  .map(function (c) {
                    return (
                      '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
                    );
                  })
                  .join('')
              );
              const newResult = JSON.parse(newJsonPayload);
              userPermissions = newResult.permissions[0];
            }
          }
        }
        commit(ActionTypes.SET_USER_ROLE_PERMISSIONS, userPermissions);
      }
    } catch (reason) {
      console.error('Error while retrieving an user permission - ', reason);
      NotificationHelper.createErrorNotification(
        i18n
          .t('global.notifications.could_not_retrieve_perimissions')
          .toString()
      );
    } finally {
      //
    }
  }
   // Sprint-24 TTD-3798
  public static async setReadOrSignDocs({
    commit,
    dispatch,
    state,
  }: ActionContext<IState, IState>): Promise<void> {
    if (state.isAppOnline) {
      const complianceService = new ComplianceService();
      try {
        await complianceService
          .getDocumentsAsync()
          .then((response) => {
            if (response !== null) {
                const count = response.filter((d) => d.scpSignedStatus === false && d.scpReadStatus === "false").length;
                commit(ActionTypes.SET_SIGN_READ_DOC_COUNT, count);
            }
          })
          .catch((reason) => {
            //
          });
      } catch (reason) {
        //
      } finally {
        //
      }
    }
    return Promise.resolve();
  }

  // TTD-3691 get company name
  public static async setCompanyNameList({commit, state, dispatch}: ActionContext<IState, IState>): Promise<void> {
    if(state.isAppOnline){
      const service = new SupplierService();
      await service.getCompanyNameAsync()
        .then((response) => {
          commit(ActionTypes.SET_COMPANY_NAMES_LIST, response);
        })
        .catch((reason) => {
          console.error("Error while fetching company names", reason);
          // NotificationHelper.createErrorNotification(i18n.t('global.notifications.could_not_retrieve_social_efforts').toString());
        })
    }
  }

  // TTD-4716 get esg social data
  public static async setEsgSocialData({commit, state, dispatch}: ActionContext<IState, IState>): Promise<void> {
    if(state.isAppOnline){
      const service = new SupplierService();
      const companyId = state.user && state.user.companyId !== null ? state.user.companyId : '';
      await service.getSocialEsgDataAsync(companyId)
        .then((response) => {
          if(response.socialData.length > 0){
          commit(ActionTypes.SET_ESG_SOCIAL_DATA, response.socialData);
          }
        })
        .catch((reason) => {
          console.error("Error while fetching esg social data", reason);
          // NotificationHelper.createErrorNotification(i18n.t('global.notifications.could_not_retrieve_social_efforts').toString());
        })
    }
  }

  // TTD-4716 get esg env data
  public static async setEsgEnvData({commit, state, dispatch}: ActionContext<IState, IState>): Promise<void> {
    if(state.isAppOnline){
      const service = new SupplierService();
      const companyId = state.user && state.user.companyId !== null ? state.user.companyId : '';
      await service.getEnvEsgDataAsync(companyId)
        .then((response) => {
          if(response.envData.length > 0){
          commit(ActionTypes.SET_ESG_ENV_DATA, response.envData);
          }
        })
        .catch((reason) => {
          console.error("Error while fetching esg env data", reason);
          // NotificationHelper.createErrorNotification(i18n.t('global.notifications.could_not_retrieve_social_efforts').toString());
        })
      }
    }
}
